import React, { useRef } from 'react';
import Flickity from 'react-flickity-component';
import { Section, SanityImage } from '../common';
import './ExternalLinksSlice.scss';

const Tile = ({
  title,
  description,
  photo,
  url,
}) => (
  <a href={url?.href} target={url?.blank ? '_blank' : ''}>
    <div className="tile">
      <div className="image-wrapper">
        <div className="hover-color" />
        <SanityImage
          className="tile-image greyscale"
          image={photo}
          alt={`A photo of ${title}`}
        />
      </div>
      <p className="tile-title">{title}</p>
      <p className="tile-description">{description?.split(/\n\n/)?.map(line => <span key={line}>{line}</span>)}</p>
    </div>
  </a>
);

const flickityOptions = {
  prevNextButtons: false,
  pageDots: false,
  cellAlign: 'left',
  contain: 'true',
};

const ExternalLinksSlice = ({ slice }) => {
  const { heading, tiles } = slice;
  const flickityRef = useRef(null);

  return (
    <section className="external-links-slice">
      <Section hasPadding hasBottomBorder noGutters>
        <Section>
          <h2 className="heading">{heading}</h2>
        </Section>
        <div className="tiles-container">
          <Flickity
            className="slider"
            options={flickityOptions}
            static
            ref={flickityRef}
          >
            {tiles?.map(tile => (
              <Tile key={tile.id} {...tile} />
            ))}
          </Flickity>
        </div>
      </Section>
    </section>
  );
};

export default ExternalLinksSlice;
