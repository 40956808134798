import React, { forwardRef } from 'react';
import { Link } from 'gatsby';
import './Logo.scss';

const Logo = forwardRef((props, ref) => (
  <Link className="logo" to="/" aria-label="Return Home" ref={ref}>
    <svg width="403" height="71" viewBox="0 0 403 71" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M132.097 33.9365V65.3742L144.761 64.6353V33.9365H132.097Z" fill="currentColor" />
      <path d="M119.385 11.5223V10.7076C119.385 5.04245 116.662 2.51304 108.538 2.51304H96.0251V31.8713H106.995V22.6726L107.962 22.6299V31.8666H119.385V24.6525C119.385 20.5316 118.248 19.0253 114.589 18.4948C117.676 17.6801 119.385 15.7664 119.385 11.5223ZM107.962 14.0801L106.995 14.1275V10.4092L107.962 10.3618V14.0801Z" fill="currentColor" />
      <path d="M126.791 2.51304V31.8666H137.029V24.2831L135.16 13.1138L139.668 31.8666H152.261V2.51304H142.188V9.03552L144.421 21.9194L139.668 2.51304H126.791Z" fill="currentColor" />
      <path d="M27.6866 54.3092L27.6677 51.0645H14.7864V57.8333L13.6772 57.8902V43.2489L14.7864 43.1921V49.7525H27.663L27.6394 45.9583C27.6394 36.6791 23.0609 33.2449 14.2342 33.2449C6.1863 33.2449 0.871399 36.9585 0.871399 46.7494V54.2666C0.871399 64.1 4.84577 67.9983 14.187 67.9983C23.5282 67.9983 27.6866 64.0953 27.6866 54.3092Z" fill="currentColor" />
      <path d="M27.0022 31.8666V6.5061L16.9294 7.09346V9.03552L19.162 21.9194L15.5464 7.17398L1.53222 7.99817V31.8666H11.7702V24.2831L9.90106 13.1138L14.4088 31.8666H27.0022Z" fill="currentColor" />
      <path d="M131.016 44.9305V33.9365H106.65V44.9305H112.569V66.5205L125.375 65.7673V44.9305H131.016Z" fill="currentColor" />
      <path d="M160.809 44.3763L160.347 57.3644L159.978 44.3763L158.265 33.9365H145.459L145.563 34.4623L151.619 64.2326L168.909 63.219L175.107 34.306L175.187 33.9365H162.428L160.809 44.3763Z" fill="currentColor" />
      <path d="M74.1991 3.73985L62.9604 4.39826L61.8275 11.6834L61.4216 23.0989L61.1006 11.6834L59.9347 4.57825L48.8943 5.22245L54.313 31.8666H68.1667L74.1991 3.73985Z" fill="currentColor" />
      <path d="M55.3751 67.3447V59.1312C55.3751 54.4418 54.077 52.7271 49.9233 52.1256C53.4351 51.1972 55.3751 49.0183 55.3751 44.1915V43.2631C55.3751 36.8117 52.2739 33.9365 43.0319 33.9365H28.7911V67.3447H41.276V56.8812L42.3805 56.8291V67.3447H55.3751ZM41.276 47.1567V42.9221L42.3805 42.87V47.0999L41.276 47.1567Z" fill="currentColor" />
      <path d="M103.129 33.9365H87.8686L80.4202 66.5679L80.2409 67.3447H92.4L92.8673 64.8863H98.135L98.5551 67.3447L110.686 66.6342L103.134 33.9412L103.129 33.9365ZM94.4816 56.2086L95.2651 51.9882L95.5436 45.4894L95.8646 51.9882L96.6057 56.2086H94.4816Z" fill="currentColor" />
      <path d="M48.1579 22.2036H38.7365V20.8584H48.1579V13.1517H38.7365V11.887H48.1579V5.26508L27.9698 6.44926V31.8666H48.1579V22.2036Z" fill="currentColor" />
      <path d="M188.271 56.346V54.816H199V46.0436H188.271V44.6036H199V33.9365H176.018V62.8021L199 61.4569V56.346H188.271Z" fill="currentColor" />
      <path d="M79.2591 44.6036V33.9365H56.2813V67.3447H79.2591V56.346H68.5301V54.816H79.2591V46.0436H68.5301V44.6036H79.2591Z" fill="currentColor" />
      <path d="M177.589 12.176H182.791V31.8666H194.044V12.176H199V2.51304H177.589V12.176Z" fill="currentColor" />
      <path d="M74.8693 31.8666H95.0574V22.2036H85.6313V20.8584H95.0574V13.1517H85.6313V11.887H95.0574V2.51778L74.8693 3.70196V31.8666Z" fill="currentColor" />
      <path d="M176.999 13.6396C176.999 5.16087 172.326 1.902 165.095 1.902C157.784 1.902 153.153 5.08035 153.153 13.6396V20.9816C153.153 29.5835 157.335 32.435 164.972 32.435C172.572 32.435 176.999 29.2945 176.999 20.9816V13.6396ZM165.633 23.0847L164.515 23.1415V11.0344L165.633 10.9823V23.0847Z" fill="currentColor" />
      <line x1="224.5" y1="1.402" x2="224.5" y2="69.402" stroke="currentColor" strokeWidth="3" />
      <path d="M370.514 70.468V63.272L366.706 50.308H371.41L372.838 56.356L372.922 57.476L373.006 56.356L374.49 50.308H379.166L375.274 63.188V70.468H370.514Z" fill="currentColor" />
      <path d="M355.824 70.468L360.052 50.308H364.588L368.9 70.468H364.308L363.944 68.256H360.752L360.388 70.468H355.824ZM362.124 59.548L361.34 64.42H363.328L362.516 59.548L362.32 57.028L362.124 59.548Z" fill="currentColor" />
      <path d="M343.897 50.308H349.553C353.053 50.308 355.545 51.904 355.545 56.132V64.616C355.545 68.872 352.997 70.468 349.497 70.468H343.897V50.308ZM349.273 54.228H348.489V66.436H349.273C350.225 66.436 350.757 65.82 350.757 64.168V56.468C350.757 54.788 350.197 54.228 349.273 54.228Z" fill="currentColor" />
      <path d="M331.439 70.804C327.267 70.804 325.755 69.04 325.755 65.568V62.712H330.487V65.484C330.487 66.268 330.683 66.744 331.551 66.744C332.335 66.744 332.503 66.268 332.503 65.484V64.224C332.503 62.908 331.159 62.208 329.703 61.368C327.911 60.332 325.923 59.044 325.923 56.076V55.04C325.923 51.568 327.883 49.972 331.523 49.972C335.051 49.972 336.927 51.596 336.927 54.704V56.888H332.447V55.012C332.447 54.06 332.223 53.724 331.495 53.724C330.795 53.724 330.543 54.116 330.543 55.012V55.684C330.543 56.804 331.803 57.392 333.231 58.176C335.051 59.184 337.151 60.5 337.151 63.58V65.652C337.151 69.068 335.331 70.804 331.439 70.804Z" fill="currentColor" />
      <path d="M320.336 56.356C321.512 56.328 322.016 55.908 322.128 54.984C322.044 55.012 321.932 55.012 321.848 55.012C320.532 55.012 320 54.704 320 52.632C320 50.588 320.532 50.112 322.268 50.112C324.032 50.112 324.564 50.616 324.564 53.276C324.564 56.552 323.22 58.316 320.336 58.316V56.356Z" fill="currentColor" />
      <path d="M306.19 70.468V50.308H310.306L313.862 60.164L314.17 61.284L314.086 59.464V50.308H318.23V70.468H314.198L310.558 60.612L310.222 59.464L310.334 61.452V70.468H306.19Z" fill="currentColor" />
      <path d="M294.952 70.468V50.308H304.724V54.452H299.488V58.204H304.164V62.404H299.488V66.156H304.808V70.468H294.952Z" fill="currentColor" />
      <path d="M279.393 70.468V50.308H283.285L286.253 58.148L289.305 50.308H293.225V70.468H288.941V60.416L289.249 58.484L289.165 58.792L286.813 64.924H285.889L283.481 58.876L283.453 58.708L283.649 60.64V70.468H279.393Z" fill="currentColor" />
      <path d="M271.846 70.776C268.206 70.776 265.882 68.984 265.882 64.756V56.02C265.882 51.904 268.262 50 271.846 50C275.346 50 277.81 51.904 277.81 56.02V64.756C277.81 68.984 275.346 70.776 271.846 70.776ZM271.846 66.548C272.742 66.548 273.022 65.988 273.022 64.392V56.244C273.022 54.648 272.742 54.06 271.846 54.06C270.95 54.06 270.67 54.676 270.67 56.244V64.392C270.67 65.96 270.95 66.548 271.846 66.548Z" fill="currentColor" />
      <path d="M250.136 70.468L247 50.308H251.368L252.544 59.688L252.684 62.88L253.076 59.716L254.728 50.308H257.724L259.264 59.716L259.6 63.048L259.824 59.688L261.112 50.308H265.508L262.064 70.468H257.808L256.324 61.816L256.156 59.1L255.96 61.816L254.42 70.468H250.136Z" fill="currentColor" />
      <path d="M393.061 25.308H397.821V40.792H402.553V45.468H393.061V25.308Z" fill="currentColor" />
      <path d="M379.23 45.468L383.458 25.308H387.994L392.306 45.468H387.714L387.35 43.256H384.158L383.794 45.468H379.23ZM385.53 34.548L384.746 39.42H386.734L385.922 34.548L385.726 32.028L385.53 34.548Z" fill="currentColor" />
      <path d="M366.429 45.468V25.308H370.545L374.101 35.164L374.409 36.284L374.325 34.464V25.308H378.469V45.468H374.437L370.797 35.612L370.461 34.464L370.573 36.452V45.468H366.429Z" fill="currentColor" />
      <path d="M358.881 45.776C355.241 45.776 352.917 43.984 352.917 39.756V31.02C352.917 26.904 355.297 25 358.881 25C362.381 25 364.845 26.904 364.845 31.02V39.756C364.845 43.984 362.381 45.776 358.881 45.776ZM358.881 41.548C359.777 41.548 360.057 40.988 360.057 39.392V31.244C360.057 29.648 359.777 29.06 358.881 29.06C357.985 29.06 357.705 29.676 357.705 31.244V39.392C357.705 40.96 357.985 41.548 358.881 41.548Z" fill="currentColor" />
      <path d="M343.207 41.1H345.363V29.648H343.207V25.308H351.915V29.648H349.815V41.1H351.915V45.468H343.207V41.1Z" fill="currentColor" />
      <path d="M334.362 45.468V29.48H331.338V25.308H341.978V29.48H338.954V45.468H334.362Z" fill="currentColor" />
      <path d="M319.702 45.468L323.93 25.308H328.466L332.778 45.468H328.186L327.822 43.256H324.63L324.266 45.468H319.702ZM326.002 34.548L325.218 39.42H327.206L326.394 34.548L326.198 32.028L326.002 34.548Z" fill="currentColor" />
      <path d="M306.901 45.468V25.308H311.017L314.573 35.164L314.881 36.284L314.797 34.464V25.308H318.941V45.468H314.909L311.269 35.612L310.933 34.464L311.045 36.452V45.468H306.901Z" fill="currentColor" />
      <path d="M294.323 45.468V25.308H300.035C303.787 25.308 305.411 26.596 305.411 30.264V32.84C305.411 34.632 304.795 35.612 303.619 36.088C304.907 36.592 305.271 37.544 305.271 39.336V44.04C305.271 44.684 305.439 45.16 305.775 45.468H301.099C300.819 45.3 300.707 44.768 300.707 44.04V39.616C300.707 38.524 300.399 38.216 299.363 38.216H298.691V45.468H294.323ZM299.727 29.144H298.691V34.156H299.727C300.651 34.156 300.959 33.82 300.959 33.008V30.432C300.959 29.508 300.651 29.144 299.727 29.144Z" fill="currentColor" />
      <path d="M283.085 45.468V25.308H292.857V29.452H287.621V33.204H292.297V37.404H287.621V41.156H292.941V45.468H283.085Z" fill="currentColor" />
      <path d="M274.096 45.468V29.48H271.072V25.308H281.712V29.48H278.688V45.468H274.096Z" fill="currentColor" />
      <path d="M257.655 45.468V25.308H261.771L265.327 35.164L265.635 36.284L265.551 34.464V25.308H269.695V45.468H265.663L262.023 35.612L261.687 34.464L261.799 36.452V45.468H257.655Z" fill="currentColor" />
      <path d="M247.504 41.1H249.66V29.648H247.504V25.308H256.212V29.648H254.112V41.1H256.212V45.468H247.504V41.1Z" fill="currentColor" />
      <path d="M344.397 20.468V4.48H341.373V0.307999H352.013V4.48H348.989V20.468H344.397Z" fill="currentColor" />
      <path d="M334.6 20.776C330.96 20.776 328.636 18.984 328.636 14.756V6.02C328.636 1.904 331.016 0 334.6 0C338.1 0 340.564 1.904 340.564 6.02V14.756C340.564 18.984 338.1 20.776 334.6 20.776ZM334.6 16.548C335.496 16.548 335.776 15.988 335.776 14.392V6.244C335.776 4.648 335.496 4.06 334.6 4.06C333.704 4.06 333.424 4.676 333.424 6.244V14.392C333.424 15.96 333.704 16.548 334.6 16.548Z" fill="currentColor" />
      <path d="M314.995 20.468V0.307999H319.111L322.667 10.164L322.975 11.284L322.891 9.464V0.307999H327.035V20.468H323.003L319.363 10.612L319.027 9.464L319.139 11.452V20.468H314.995Z" fill="currentColor" />
      <path d="M296.948 20.468V0.307999H302.66C306.412 0.307999 308.036 1.596 308.036 5.264V7.84C308.036 9.632 307.42 10.612 306.244 11.088C307.532 11.592 307.896 12.544 307.896 14.336V19.04C307.896 19.684 308.064 20.16 308.4 20.468H303.724C303.444 20.3 303.332 19.768 303.332 19.04V14.616C303.332 13.524 303.024 13.216 301.988 13.216H301.316V20.468H296.948ZM302.352 4.144H301.316V9.156H302.352C303.276 9.156 303.584 8.82 303.584 8.008V5.432C303.584 4.508 303.276 4.144 302.352 4.144Z" fill="currentColor" />
      <path d="M285.71 20.468V0.307999H295.482V4.452H290.246V8.204H294.922V12.404H290.246V16.156H295.566V20.468H285.71Z" fill="currentColor" />
      <path d="M276.136 20.468L272.02 0.307999H276.668L278.292 9.772L278.46 12.32L278.6 9.772L280.14 0.307999H284.76L280.756 20.468H276.136Z" fill="currentColor" />
      <path d="M261.565 20.468V0.307999H271.337V4.452H266.101V8.204H270.777V12.404H266.101V16.156H271.421V20.468H261.565Z" fill="currentColor" />
      <path d="M247.784 20.468V0.307999H251.9L255.456 10.164L255.764 11.284L255.68 9.464V0.307999H259.824V20.468H255.792L252.152 10.612L251.816 9.464L251.928 11.452V20.468H247.784Z" fill="currentColor" />
    </svg>
  </Link>
));

Logo.displayName = 'Logo';

export default Logo;
