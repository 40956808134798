import { Link } from 'gatsby';
import React from 'react';
import { SanityImage } from '../../../common';
import './PlaylistItem.scss';

const PlaylistItem = ({ track, isCurrentlyPlaying, isUpNext }) => {
  const {
    title,
    description,
    speaker,
    thumbnail,
    timeLabel,
    slug,
  } = track;
  return (
    <div className="playlist-item">
      {isCurrentlyPlaying && (
        <span className="playlist-label">Currently Playing</span>
      )}
      {isUpNext && (
        <span className="playlist-label up-next">Up Next</span>
      )}
      <div className="container">
        <div className="container__header">
          <span className="time-label">{timeLabel}</span>
          {!isCurrentlyPlaying && <span className="watch-now-container"><Link className="watch-now" to={`/video/${slug?.current}`}>Watch Now</Link></span>}
        </div>
        <div className="thumbnail">
          <SanityImage
            className="preview-thumbnail"
            image={thumbnail}
            alt={`A preview thumbnail of "${title}"`}
          />
        </div>
        <span className="track-title">{title}</span>
        <span className="speaker">{speaker}</span>
        <p className="description">{description}</p>
      </div>
    </div>
  );
};

export default PlaylistItem;
