import React from 'react';
import { Section, RichText } from '../common';
import './AboutSlice.scss';

const AboutSlice = ({ slice, hideCta, noBorder }) => {
  const {
    featuredText,
    body,
    callToAction,
  } = slice;

  return (
    <section id="about" className={`about-slice ${noBorder ? 'no-border' : ''}`}>
      <Section hasGrid>
        <div className="col col-left">
          <p className="featuredText">{featuredText}</p>
        </div>
        <div className="col col-right">
          <div className="container">
            <RichText className="contact-text" blocks={body || []} />
          </div>
          {!hideCta && (
            <a
              className="call-to-action-button"
              href={callToAction.href}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={`Open a new tab to ${callToAction.label}`}
            >
              {callToAction.label}
            </a>
          )}
        </div>
      </Section>
    </section>
  );
};

export default AboutSlice;
