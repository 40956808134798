import React from 'react';
import { Section } from '../common';
import './TextSlice.scss';

const TextSlice = ({ slice }) => {
  const { title, firstColumn, secondColumn } = slice;

  return (
    <section className="text-slice">
      <Section hasPadding hasBottomBorder noGutters>
        <Section>
          <h2 className="title">{title}</h2>
        </Section>
        <Section className="text-container">
          <p>{firstColumn}</p>
          <p>{secondColumn}</p>
        </Section>
      </Section>
    </section>
  );
};

export default TextSlice;
