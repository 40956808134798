import React, { useRef } from 'react';
import Flickity from 'react-flickity-component';
import { Section, SanityImage } from '../common';
import './ProfilesSlice.scss';

const ArrowIcon = ({ direction = 'right' }) => (
  <svg className={direction === 'left' ? 'flip' : ''} width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L6 6L1 11" stroke="white" />
  </svg>
);

const Profile = ({
  name,
  bio,
  greyScale,
  photo,
}) => (
  <div className="profile">
    <div className="image-wrapper">
      <SanityImage
        className={`profile-image${greyScale ? ' greyscale' : ''}`}
        image={photo}
        alt={`A photo of ${name}`}
      />
    </div>
    <p className="profile-name">{name}</p>
    <p className="profile-bio">{bio.split(/\n\n/).map(line => <span key={line}>{line}</span>)}</p>
  </div>
);

const flickityOptions = {
  prevNextButtons: false,
  pageDots: false,
  cellAlign: 'left',
  contain: 'true',
};

const ProfilesSlice = ({ slice }) => {
  const { title, profiles, greyscaleFilter } = slice;
  const flickityRef = useRef(null);

  const handleNext = () => {
    if (flickityRef.current && flickityRef.current.flkty) {
      flickityRef.current.flkty.next();
    }
  };

  const handlePrev = () => {
    if (flickityRef.current && flickityRef.current.flkty) {
      flickityRef.current.flkty.previous();
    }
  };

  const showPagination = profiles?.length > 4;

  return (
    <section className="profiles-slice">
      <Section hasPadding hasBottomBorder noGutters>
        <Section>
          <h2 className="title">{title}</h2>
        </Section>
        <div className="profiles-container">
          {showPagination && (
            <div className="pagination">
              <div className="button-container">
                <div
                  className="next"
                  onClick={handleNext}
                  onKeyDown={e => e.key === 'Enter' && handleNext()}
                  role="button"
                  tabIndex={0}
                >
                  <ArrowIcon direction="right" />
                </div>
                <div
                  className="prev"
                  onClick={handlePrev}
                  onKeyDown={e => e.key === 'Enter' && handlePrev()}
                  role="button"
                  tabIndex={0}
                >
                  <ArrowIcon direction="left" />
                </div>
              </div>
            </div>
          )}
          <Flickity
            className="slider"
            options={flickityOptions}
            static
            ref={flickityRef}
          >
            {profiles.map(profile => (
              <Profile key={profile.id} greyScale={greyscaleFilter} {...profile} />
            ))}
          </Flickity>
        </div>
      </Section>
    </section>
  );
};

export default ProfilesSlice;
