import React from 'react';
import { SanityImage, Section } from '../common';
import './PartnersSlice.scss';

const PartnersSlice = ({ slice }) => {
  const { title, partners } = slice;

  return (
    <section className="partners-slice">
      <Section hasPadding hasBottomBorder noGutters>
        <Section>
          <h2 className="title">{title}</h2>
        </Section>
        {partners?.length > 0 && (
          <Section className="partners-container">
            {partners.map(partner => (
              <a className="partner" href={partner?.link} target="_blank" rel="noreferrer" key={partner?.id}>
                <div className="image-wrapper">
                  <SanityImage
                    className="partner-image greyscale"
                    image={partner.logo}
                    alt={`${partner.title}'s Logo`}
                  />
                </div>
                <p className="name">{partner?.title}</p>
              </a>
            ))}
          </Section>
        )}
      </Section>
    </section>
  );
};

export default PartnersSlice;
