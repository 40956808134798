import { useMemo, useState } from 'react';

export default (playlist) => {
  const numTracks = useMemo(() => playlist.length, []);
  const videoRanges = useMemo(() => {
    const ranges = [];
    let currentStart = 0;
    playlist.forEach((track) => {
      const {
        id,
        videoDuration,
      } = track;
      ranges.push({
        id,
        duration: videoDuration,
        start: currentStart,
        end: currentStart + videoDuration,
      });
      currentStart += videoDuration;
    });
    return ranges;
  }, [playlist]);
  const totalPlaylistDuration = videoRanges[videoRanges.length - 1].end;
  const streamSeconds = Math.floor((Date.now() / 1000) % totalPlaylistDuration);

  const currentTrackIndex = useMemo(() => videoRanges.findIndex(track => (
    track.start <= streamSeconds && track.end > streamSeconds
  )));
  const [trackIndex, setTrackIndex] = useState(currentTrackIndex);
  const trackSeconds = streamSeconds - videoRanges[trackIndex].start;

  return {
    trackIndex,
    trackSeconds,
    setTrackIndex,
    streamSeconds,
    totalPlaylistDuration,
    videoRanges,
    numTracks,
  };
};
